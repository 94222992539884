import Vue from 'vue';
import VueRouter from 'vue-router';

import Activate from '@/views/Activate';
import Metronome from '@/views/Metronome';

Vue.use(VueRouter);

const routes = [{
        path: '/',
        redirect: '/redirect'
    },
    {
        path: '/metronome',
        component: Metronome,
    },
    {
        path: '/activate',
        component: Activate,
    },

]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('TOKEN') || to.query.token;
    if (to.path === '/redirect') {
        window.location.href = "http://kaoyan.since88.cn/wechat/authorize?returnUrl=jasmine";
    }
    if (to.path === '/metronome') {
        if (!token || token === 'undefined') {
            window.location.href = "http://kaoyan.since88.cn/wechat/authorize?returnUrl=jasmine";
        }
    }
    next();
})

export default router;