<template>
    <div :style="{ height: initHeight }">
        <header>
            <van-nav-bar title="节拍器激活" />
        </header>
        <main>
            <div class="svgbox">
                <svg
                    t="1662798452269"
                    class="icon svg"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1967"
                    width="150"
                    height="150"
                >
                    <path
                        d="M642.88 1012.12h-262.5c-203.65 0-368.75-165.09-368.75-368.75v-262.5c0-203.65 165.1-368.75 368.75-368.75h262.5c203.65 0 368.75 165.1 368.75 368.75v262.5c0 203.65-165.1 368.75-368.75 368.75z"
                        fill="#3c89f2"
                        p-id="1968"
                    ></path>
                    <path
                        d="M746.87 842.71H314.88c-8.09 0-15.75-3.62-20.88-9.88a27.026 27.026 0 0 1-5.59-22.42l24-120c2.92-14.61 17.04-24.19 31.77-21.17 14.62 2.92 24.1 17.14 21.17 31.77l-17.54 87.7H714.3L604.49 206.74H468.85l-30.4 146.48c-3.02 14.6-17.28 24.02-31.92 20.95-14.6-3.04-23.98-17.32-20.95-31.92l34.86-167.99c2.6-12.53 13.64-21.51 26.44-21.51h179.99c12.98 0 24.13 9.23 26.53 22l120 635.98a26.98 26.98 0 0 1-5.74 22.23 27.06 27.06 0 0 1-20.79 9.75zM312.39 481.07c-3.84 0-7.68-1.46-10.61-4.39l-48-48c-5.86-5.86-5.86-15.35 0-21.21 5.86-5.86 15.35-5.86 21.21 0l48 48c5.86 5.86 5.86 15.35 0 21.21a14.93 14.93 0 0 1-10.6 4.39z"
                        fill="#FFFFFF"
                        p-id="1969"
                    ></path>
                    <path
                        d="M698.24 681.51H504.38c-4.23 0-8.26-1.78-11.1-4.91l-84-92.45c-5.58-6.13-5.12-15.62 1.01-21.19 6.13-5.57 15.64-5.12 21.19 1.01l79.53 87.54h187.22c8.28 0 15 6.71 15 15s-6.71 15-14.99 15zM564.38 385.08h-60c-14.91 0-27-12.09-27-27s12.09-27 27-27h60c14.91 0 27 12.09 27 27s-12.1 27-27 27zM564.38 493.07h-60c-14.91 0-27-12.09-27-27s12.09-27 27-27h60c14.91 0 27 12.09 27 27s-12.1 27-27 27zM366.39 484.07c19.88 0 36 16.12 36 36s-16.12 36-36 36-36-16.12-36-36 16.11-36 36-36m0-30c-36.39 0-66 29.61-66 66s29.61 66 66 66 66-29.61 66-66c-0.01-36.39-29.61-66-66-66z"
                        fill="#FFFFFF"
                        p-id="1970"
                    ></path>
                </svg>
            </div>

            <div class="input_body">
                <input
                    type="text"
                    placeholder="请输入激活码"
                    class="input"
                    autofocus
                    v-model="value"
                />
            </div>
            <div class="activate_body">
                <van-button
                    type="info"
                    class="activate_button"
                    @click="activateUser"
                    >确认激活
                </van-button>
            </div>
            <div class="activate_instruction">
                <h3 class="activate_instruction_title">激活说明</h3>
                <ol>
                    <li>1.激活码为一串字母与数字的组合</li>
                    <li>2.输入正确激活码后即可使用本软件</li>
                    <li>
                        3.激活码存在有效期，逾期自动失效，不补发不延期，请在有效期内及时激活
                    </li>
                    <li>4.激活码仅限激活本软件，不可兑换现金/找零</li>
                    <li>
                        5.同一个激活码仅限激活一次，成功激活后激活码失效，不可重复使用
                    </li>
                </ol>
            </div>
        </main>
    </div>
</template>

<script>
import { reqActivateUser } from "@/api/index.js";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import { wxShareInit } from '@/utils/useWxSdk';
export default {
    name: "Activate",
    data() {
        return {
            value: "",
            initHeight: "",
        };
    },
    mounted() {
        const token = this.$route.query.token;
        localStorage.setItem("TOKEN", token);
        this.init();
        this.wxShare();
    },
    methods: {
        init() {
            this.initHeight = window.innerHeight + "px";
        },
        async activateUser() {
            const res = await reqActivateUser(this.value);
            if (res.data.code === "0") {
                Toast.success("激活成功");
                setTimeout(() => {
                    this.$router.push({ path: "/metronome" });
                }, 500);
            } else {
                Toast.fail(res.data.errMsg);
            }
        },
        wxShare() {
            try {
                const url = window.location.href.split("#")[0];
                wxShareInit(url);
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>

<style scoped>
.van-field {
    border-radius: 15px;
}
main {
    background-color: #fff;
    /* height: 100vh; */
}
.svgbox {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 20px;
}
.svg {
    border-radius: 56px;
    background: linear-gradient(315deg, #f0f0f0, #cacaca);
}
.input_body {
    display: flex;
    justify-content: center;
    padding: 20px 0 10px 0;
}

.input {
    display: block;
    height: 40px;
    width: 280px;
    border: 2px solid #ccc;
    border-radius: 20px;
    padding: 0 10px;
    text-align: center;
    /* outline: 0; */
}
.activate_body {
    display: flex;
    justify-content: center;
}
.activate_button {
    margin-top: 10px;
    width: 300px;
    height: 40px;
    border-radius: 20px;
}
.activate_instruction {
    padding: 20px 20px;
}
.activate_instruction_title {
    margin: 20px 0;
    font-weight: 700;
    font-size: 17px;
}
.activate_instruction ol li {
    margin-bottom: 4px;
    color: #8e8e8e;
}
</style>